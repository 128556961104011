<template>
  <div id="app">
    <div class="navbar-fixed" v-if="!$route.meta.hideNavbar">
      <nav v-if="showStudentMenu">
        <div class="nav-wrapper container">
          <a
            @click="$router.back()"
            :class="{ hide: !child }"
            class="hide-on-med-and-up button-back icon"
            title="Back"
          >
            <svg
              fill="#fff"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              style="position: relative; top: 8px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg>
          </a>
          <a
            data-activates="nav-items"
            class="hide-on-large-only button-collapse icon"
            :class="{ hasChild: child }"
            title="Menu"
            @click="showDrawer = !showDrawer"
          >
            <svg
              fill="#fff"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              style="position: relative; top: 8px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
            </svg>
          </a>

          <router-link
            class="brand-logo"
            v-if="user && user.role === 'Affiliation Head'"
            to="/AffiliationDashboard"
          >
            <img
              v-if="user.affiliation.LogoURL"
              :src="user.affiliation.LogoURL"
              class="responsive-img"
            />
            <img v-else :src="require(`./assets/sl-logo.png`)" />
          </router-link>
          <router-link
            class="brand-logo"
            v-if="user && user.role && user.role.includes('Faculty')"
            to="/AffiliationDashboard"
          >
            <img
              v-if="user.affiliation.LogoURL"
              :src="user.affiliation.LogoURL"
              class="responsive-img"
            />
            <img v-else :src="require(`./assets/sl-logo.png`)" />
          </router-link>
          <router-link
            class="brand-logo"
            v-if="user.role.includes('Student')"
            to="/StudentDashboard"
          >
            <img
              v-if="user.affiliation.LogoURL"
              :src="user.affiliation.LogoURL"
              class="responsive-img"
            />
            <img v-else :src="require(`./assets/sl-logo.png`)" />
          </router-link>

          <ul id="nav-mobile" class="right">
            <!-- Student START -->
            <div style="display: inline">
              <li
                class="hide-on-small-and-down"
                v-for="menu in this.roleFeatures.filter((a) => a.ParentId === 0)"
                :key="`dmlit${menu.ApplicationMenuRoleId}`"
              >
                <span
                  v-if="
                    this.roleFeatures.filter((a) => a.ParentId === menu.ApplicationMenuRoleId)
                      .length == 0
                  "
                >
                  <template v-if="menu.URL.includes('aspx')">
                    <a
                      class="task drawer-main-menu"
                      :v-if="menu && parseInt(menu.ParentId) === 0"
                      :href="`${baseUrl}/${menu.URL}`"
                      >{{ menu.Name }}</a
                    >
                  </template>
                  <template v-else>
                    <router-link :to="`/${menu.URL}`">{{ menu.Name }}</router-link>
                  </template>
                </span>
                <span v-else>
                  <span v-if="menu.Name != 'Profile'">
                    <a class="task" :v-if="menu && parseInt(menu.ParentId) === 0"
                      >{{ menu.Name }}
                      <div
                        class="menu-dropdown"
                        v-if="
                          this.roleFeatures.filter((a) => a.ParentId === menu.ApplicationMenuRoleId)
                            .length > 0
                        "
                      >
                        <ul>
                          <li
                            v-for="menuItem in this.roleFeatures.filter(
                              (a) => a.ParentId === menu.ApplicationMenuRoleId,
                            )"
                            :key="`dmit${menuItem.ApplicationMenuRoleId}`"
                          >
                            <template v-if="menuItem.URL.includes('aspx')">
                              <a
                                :v-if="
                                  menuItem &&
                                  menuItem.ParentId != 0 &&
                                  menuItem.ParentId === menu.ApplicationMenuRoleId
                                "
                                :href="`${baseUrl}/${menuItem.URL}`"
                                >{{ menuItem.Name }}</a
                              >
                            </template>
                            <template v-else>
                              <router-link :to="`/${menuItem.URL}`">{{
                                menuItem.Name
                              }}</router-link>
                            </template>
                          </li>
                        </ul>
                      </div>
                    </a>
                  </span>
                  <span v-else>
                    <a class="icon-myacc"
                      ><img :src="require(`./assets/default-user.svg`)" alt="profile" width="32" />
                      <div class="profile-menu">
                        <ul>
                          <li v-if="hasMultipleProfiles">
                            <a @click="switchProfile()">Switch Profile</a>
                          </li>
                          <li
                            v-for="menuItem in this.roleFeatures.filter(
                              (a) => a.ParentId === menu.ApplicationMenuRoleId,
                            )"
                            :key="`dmit${menuItem.ApplicationMenuRoleId}`"
                          >
                            <template v-if="menuItem.URL.includes('aspx')">
                              <a
                                :v-if="
                                  menuItem &&
                                  menuItem.ParentId != 0 &&
                                  menuItem.ParentId === menu.ApplicationMenuRoleId
                                "
                                :href="`${baseUrl}/${menuItem.URL}`"
                                >{{ menuItem.Name }}</a
                              >
                            </template>
                            <template v-else>
                              <router-link :to="`/${menuItem.URL}`">{{
                                menuItem.Name
                              }}</router-link>
                            </template>
                          </li>
                          <li>
                            <a :href="`${baseUrl}/Logout.aspx`" @click="doLogout()"
                              ><i class="small material-icons logout-icon" style="font-size: 1rem"
                                >logout</i
                              >
                              Logout</a
                            >
                          </li>
                        </ul>
                      </div>
                    </a>
                  </span>
                </span>
              </li>
            </div>
            <!-- Student END -->
            <li v-if="['Affiliation Head', 'Faculty'].includes(user.role)">
              <a class="icon-myacc"
                ><img :src="require(`./assets/default-user.svg`)" alt="profile" width="32" />
                <div class="profile-menu">
                  <ul>
                    <li v-if="hasMultipleProfiles">
                      <a @click="switchProfile()">Switch Profile</a>
                    </li>
                    <li v-if="['Affiliation Head'].includes(user.role)">
                      <router-link
                        v-if="['Affiliation Head'].includes(user.role)"
                        to="/ChangePassword"
                        >Change Password</router-link
                      >
                    </li>
                    <li v-if="['Affiliation Head'].includes(user.role)">
                      <router-link
                        v-if="['Affiliation Head'].includes(user.role)"
                        to="/NoticeLinkHistory"
                        >Notices</router-link
                      >
                    </li>
                    <li v-if="['Affiliation Head'].includes(user.role)">
                      <router-link
                        v-if="['Affiliation Head'].includes(user.role)"
                        to="/AffiliationProfile"
                        >Affiliation Profile</router-link
                      >
                    </li>
                    <li>
                      <a :href="`${baseUrl}/Logout.aspx`" @click="doLogout()"
                        ><i class="small material-icons logout-icon" style="font-size: 1rem"
                          >logout</i
                        >
                        Logout</a
                      >
                    </li>
                  </ul>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <!-- Navigation Drawer -->
    <ul
      :class="{ active: showDrawer }"
      class="sidenav"
      id="nav-items"
      v-if="!$route.meta.hideNavbar"
    >
      <li>
        <div class="sidenav-header">
          <a
            v-if="user.role === 'Affiliation Head'"
            :href="`${baseUrl}/AffiliationDashBoard.aspx`"
            class="brand-logo"
          >
            <img
              v-if="user.affiliation.LogoURL"
              :src="user.affiliation.LogoURL"
              class="responsive-img"
            />
            <img v-else :src="require(`./assets/sl-logo.png`)" />
          </a>
          <a
            v-if="user.role === 'Faculty'"
            :href="`${baseUrl}/ManageUsers.aspx`"
            class="brand-logo"
          >
            <img
              v-if="user.affiliation.LogoURL"
              :src="user.affiliation.LogoURL"
              class="responsive-img"
            />
            <img v-else :src="require(`./assets/sl-logo.png`)" />
          </a>
          <router-link
            v-if="user.role.includes('Student')"
            class="brand-logo"
            to="/StudentDashboard"
          >
            <img
              v-if="user.affiliation.LogoURL"
              :src="user.affiliation.LogoURL"
              class="responsive-img"
            />
            <img v-else :src="require(`./assets/sl-logo.png`)" />
          </router-link>
        </div>
      </li>
      <!-- Student START -->
      <div v-if="showStudentMenu" style="display: inline">
        <li
          v-for="menu in this.roleFeatures.filter((a) => a.ParentId === 0)"
          :key="`dmlitd${menu.ApplicationMenuRoleId}`"
        >
          <template
            v-if="
              this.roleFeatures.filter((a) => a.ParentId === menu.ApplicationMenuRoleId).length == 0
            "
          >
            <template v-if="menu.URL.includes('aspx')">
              <a
                class="task drawer-main-menu"
                :v-if="menu && parseInt(menu.ParentId) === 0"
                :href="`${baseUrl}/${menu.Name}`"
                >{{ menu.URL }}</a
              >
            </template>
            <template v-else>
              <router-link :to="`/${menu.URL}`">{{ menu.Name }}</router-link>
            </template>
          </template>
          <template v-else>
            <a class="task drawer-main-menu" :v-if="menu && parseInt(menu.ParentId) === 0"
              >{{ menu.Name }}
              <span class="material-icons arrow-right">keyboard_arrow_right</span>
              <span class="material-icons expand_more">expand_more</span>
              <div
                class="menu-dropdown drawer-dropdown"
                style="z-index: 10"
                v-if="
                  this.roleFeatures.filter((a) => a.ParentId === menu.ApplicationMenuRoleId)
                    .length > 0
                "
              >
                <ul>
                  <li
                    v-for="menuItem in this.roleFeatures.filter(
                      (a) => a.ParentId === menu.ApplicationMenuRoleId,
                    )"
                    :key="`dmit1${menuItem.ApplicationMenuRoleId}`"
                    @click="showDrawer = false"
                  >
                    <template v-if="menuItem.URL.includes('aspx')">
                      <a
                        :v-if="
                          menuItem &&
                          menuItem.ParentId != 0 &&
                          menuItem.ParentId === menu.ApplicationMenuRoleId
                        "
                        :href="`${baseUrl}/${menuItem.URL}`"
                        >{{ menuItem.Name }}</a
                      >
                    </template>
                    <template v-else>
                      <router-link :to="`/${menuItem.URL}`">{{ menuItem.Name }}</router-link>
                    </template>
                  </li>
                </ul>
              </div>
            </a>
          </template>
        </li>
      </div>
    </ul>

    <Modal
      :show="showModal"
      @close="showModal = false"
      :showHeader="true"
      width="80%"
      :showCloseBtn="true"
    >
      <template v-slot:header>
        <div class="row nmb">
          <div class="col s3 m1">
            <img
              :src="`${baseUrl}/assets/img/icons/noticeboard.svg`"
              alt="noticeBoard"
              style="vertical-align: middle"
            />
          </div>
          <div class="col s9 m11">
            <h5>Noticeboard</h5>
          </div>
        </div>
      </template>
      <template v-slot:body style="overflow-y: auto">
        <div class="row">
          <div
            class="col s12"
            v-for="(notification, index) in notifications"
            :key="index"
            style="line-height: 2"
          >
            &bullet; {{ notification.Title }} - {{ notification.Description
            }}<span v-if="notification.LinkURL">
              - <a target="_new" :href="notification.LinkURL">Click Here</a></span
            >
          </div>
        </div>
      </template>
    </Modal>

    <!-- Router View -->
    <!-- <div :class="{ notready: !routerReady }">
            <keep-alive>
                <router-view class="container page-content" :key="$route.fullPath"></router-view>
            </keep-alive>
        </div> -->
    <div :class="{ notready: !routerReady }">
      <router-view v-slot="{ Component }" class="container page-content" :key="$route.fullPath">
        <transition>
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </transition>
      </router-view>
    </div>

    <div id="toast">
      <div id="desc">{{ chatNotification }}</div>
    </div>

    <!-- <nprogress-container></nprogress-container> -->

    <div
      class="sidenav-overlay"
      style="opacity: 1; display: block"
      v-show="showDrawer"
      @click="showDrawer = false"
    >
      <svg
        fill="#FFF"
        height="32"
        viewBox="0 0 24 24"
        width="32"
        style="float: right; position: relative; top: 16px; right: 16px"
      >
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>

    <Loader></Loader>
  </div>
</template>

<script>
import "materialize-css";
import { mapGetters } from "vuex";
import Pusher from "pusher-js";
import { useToast } from "vue-toastification";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import { useRouter } from "vue-router";
import API from "./Api";
import Loader from "./components/Loader";
import Modal from "./components/Modal.vue";
import Mobileapi from "./Mobileapi";
import "./assets/css/custom.css";
import Modal3Vue from "./components/Modal3.vue";
import Doubtapi from "./Doubtapi";

const toast = useToast();

export default {
  name: "app",
  data() {
    return {
      baseUrl: "",
      showModal: false,
      showDrawer: false,
      notifications: [],
      appUser: null,
      features: [],
      profileData: [],
      courseId: null,
      batchId: null,
      chatNotification: "",
      roleFeatures: [],
      isMobileDevice: false,
      showSwitcherModal: false,
      hasMultipleProfiles: false,
      profilesList: [],
    };
  },
  created() {
    this.appUser = this.$store.getters.user;
    if (this.loggedIn) {
      this.$store.dispatch("getProfile");
    }
  },
  mounted() {
    const route = useRouter();
    if (!["/login", "/signup"].includes(route.options.history.location)) {
      this.baseUrl = this.user.DomainName;
      window.oncontextmenu = (e) => {
        toast.error("Right Click Disabled");
        e.preventDefault();
      };
      API.getNotifications(
        {},
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.notifications = response;
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
      API.getFeature(
        {
          affiliationId: this.appUser.AffiliationId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.features = response.data;
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
      API.getProfile((profile) => {
        this.profileData = profile;
        this.hasMultipleProfiles = this.profileData.hasMultipleProfiles;
        this.batchId = this.profileData.CenterCodeId;
        this.courseId = this.profileData.CourseId;
        const pusher = new Pusher("6623492ea6c4e19d159a", {
          cluster: "ap2",
        });
        const channel = pusher.subscribe(`groupchatnotification${this.courseId}-${this.batchId}`);
        channel.bind("notification", (data) => {
          this.chatNotification = data.notification;
          this.launchToast();
        });
      });
      this.getRoleFeatures();
      this.hotjarIdentityHandler();
      // this.userGuidingHandler();
    }
  },
  updated() {
    // console.log("App updated");
  },
  methods: {
    hotjarIdentityHandler() {
      this.appUser = this.$store.getters.user;
      // eslint-disable-next-line no-undef
      hj("identify", this.appUser.UserId, {
        email: this.appUser.UserName,
      });
    },
    // userGuidingHandler() {
    //   // eslint-disable-next-line no-restricted-globals
    //   if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //     this.isMobileDevice = true;
    //   } else {
    //     this.isMobileDevice = false;
    //   }
    //   // eslint-disable-next-line no-restricted-globals
    //   window.userGuiding.identify(this.appUser.UserId, {
    //     email: this.appUser.UserName,
    //     role: this.appUser.role,
    //     isMobile: this.isMobileDevice,
    //   });
    // },
    getRoleFeatures() {
      this.roleFeatures = [];
      Mobileapi.getRoleMenu(
        {},
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.roleFeatures = response.data;
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    doLogout() {
      localStorage.removeItem("SLToken");
      localStorage.removeItem("ThirdPartySource");
      localStorage.removeItem("DashboardData");
      localStorage.removeItem("TeacherDashboardData");
      localStorage.removeItem("DashboardRefreshTime");
      localStorage.removeItem("practiceSelectedSubjectData");
      localStorage.removeItem("PracticeSessionRefreshData");
      window.location = `${process.env.VUE_APP_SL_URL}/Logout.aspx`;
      return true;
    },
    launchToast() {
      const x = document.getElementById("toast");
      x.className = "show";
      setTimeout(() => {
        x.className = x.className.replace("show", "");
        this.chatnotification = "";
      }, 15000);
    },
    featureAccess(fname, UiName) {
      let visibleUrl = true;
      if (this.features !== "undefined" || this.features !== null) {
        const filtered = this.features.some((item) => item.URL === fname && item.Name === UiName);
        if (!filtered) {
          visibleUrl = false;
        }
      }
      return visibleUrl;
    },
    switchProfile() {
      Mobileapi.getUserMultipleProfiles(
        {},
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.profilesList = response.data;
          if (this.profilesList != null && this.profilesList.length > 0) {
            if (this.profilesList.length === 1) {
              this.changeUserProfile(this.profilesList[0]);
            } else {
              this.showSwitcherModal = true;
            }
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    changeUserProfile(data) {
      const emailId = data.EmailId;
      const apiObj = { emailId };
      Doubtapi.getSwitchedUserData(
        apiObj,
        (res) => {
          this.$store.dispatch("showLoader", false);
          if (res.statusCode === 200) {
            const dashboardPath = this.getDashboardPath(res.data.role);
            window.location.href = `${process.env.VUE_APP_URL}/sso?token=${res.data.token}&redirectTo=${dashboardPath}`;
            this.$store.dispatch("showLoader", false);
          } else {
            this.showOtpError = true;
            this.$store.dispatch("showLoader", false);
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    getDashboardPath(role) {
      if (["Student", "Demo Student", "Deactive Demo Student"].includes(role)) {
        return "StudentDashboard";
      }
      if (["Affiliation Head", "Faculty"].includes(role)) {
        return "AffiliationDashboard";
      }
      return "StudentDashboard";
    },
  },
  computed: {
    child() {
      return this.$route.path !== "/";
    },
    showStudentMenu() {
      return !["TestInstruction", "FullLengthTest"].includes(this.$route.name);
    },
    ...mapGetters(["routerReady", "user", "loggedIn"]),
  },
  components: {
    NprogressContainer,
    Loader,
    Modal,
    Modal3Vue,
  },
};
</script>

<style lang="scss">
#nprogress .bar {
  background: #ff8f1f !important;
  height: 4px;
}

#nprogress .peg {
  box-shadow: 0 0 10px #ff8f1f, 0 0 5px #ff8f1f !important;
}

#nprogress .spinner {
  display: none !important;
}

$primary-color: #6a2f85;
$secondary-color: #338510;
@import "~materialize-css/sass/components/color-variables";
@import "~materialize-css/sass/components/color-classes";
// Variables;
@import "~materialize-css/sass/components/variables";
$large-and-down: "only screen and (max-width : #{$large-screen})" !default;

.hide-on-large-and-down {
  @media #{$large-and-down} {
    display: none !important;
  }
}

// Reset
@import "~materialize-css/sass/components/normalize";
// components
@import "~materialize-css/sass/components/global";
@import "~materialize-css/sass/components/badges";
@import "~materialize-css/sass/components/icons-material-design";
@import "~materialize-css/sass/components/grid";

.col .row {
  margin-left: 0;
  margin-right: 0;
}

@import "~materialize-css/sass/components/navbar";

.brand-logo img {
  // height: 64px;
  height: 40px;
  top: 12px;
  position: relative;
  margin-left: 10px;
}

@media only screen and (max-width: 992px) {
  .container {
    width: 100%;
  }

  nav a {
    padding: 0 10px;
    display: inline-block;
  }

  nav .brand-logo {
    left: unset;
    transform: none;
    padding: 0 10px;
  }

  .brand-logo img {
    margin-left: 0;
  }
}

@import "~materialize-css/sass/components/typography";
// html {
//     @media only screen and (min-width: $medium-screen) {
//         font-size: 14px;
//     }
//     @media only screen and (min-width: $large-screen) {
//         font-size: 14px;
//     }
// }
// @import "components/transitions";
@import "~materialize-css/sass/components/cards";
@import "~materialize-css/sass/components/toast";

#toast-container {
  z-index: 100000;
}

@import "~materialize-css/sass/components/tabs";
// @import "components/tooltip";
$button-radius: 5px;
// $button-padding: 0 24px;
// $primary-color: #338510 !important;
@import "~materialize-css/sass/components/buttons";

.btn {
  box-shadow: none;
}

.btn-flat.border {
  border: 1px solid $secondary-color;
  color: $secondary-color;
}

// .btn-flat:disabled, .btn-flat.disabled {
//     border: 1px solid #DFDFDF;
// }
.btn-100 {
  width: 100%;
}

// @import "components/dropdown";
@import "~materialize-css/sass/components/waves";
// @import "components/modal";
@import "~materialize-css/sass/components/collapsible";
@import "~materialize-css/sass/components/chips";
// @import "components/materialbox";
@import "~materialize-css/sass/components/forms/forms";

input[type="text"].browser-default,
input[type="number"].browser-default {
  padding: 12px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 2px;
}

@import "~materialize-css/sass/components/sidenav";

.sidenav {
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

#nav-items {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  //   width: 280px;
  box-shadow: none;
}

#nav-items.active {
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.sidenav-overlay {
  z-index: 997;
  background-color: rgba(0, 0, 0, 0.75);
}

.sidenav .subheader {
  font-size: 12px;
}

.sidenav li > a {
  padding: 0 22px;
  font-weight: 400;
}

.sidenav li > a.category {
  border-bottom: 1px solid #efefef;
  font-weight: 500;
}

.sidenav li > a.category.level2,
.sidenav li > a.category.level3 {
  font-weight: 300;
}

.sidenav li > a.category.level3 {
  padding: 0 32px;
}

.sidenav li > a.category.level2.expand {
  font-weight: 500;
}

.sidenav li > a.category > i {
  margin: 0;
}

.sidenav li > a.category.level2 > i {
  font-size: 16px;
  text-align: center;
}

.sidenav li.nav-home > a > i,
.nav-home a {
  color: #fff !important;
  line-height: 64px !important;
  height: 64px !important;
}

.sidenav li a i {
  margin-right: 10px;
  height: 48px;
  line-height: 48px;
}

.sidenav li a svg {
  margin-right: 10px;
  line-height: 48px;
  vertical-align: middle;
}

.sidenav-header {
  padding: 8px 16px 0;
  margin-bottom: 8px;
  background-color: #6a2f85;
}

.sidenav-header .circle {
  height: 64px;
  width: 64px;
}

.sidenav-header a span {
  display: block;
  font-size: 14px;
  line-height: 24px;
}

@import "~materialize-css/sass/components/preloader";

@media only screen and (min-width: 993px) {
  .container {
    width: 80%;
  }
}

a {
  cursor: pointer !important;
}

.slcolor {
  background-color: #6a2f85;
}

.slcolor-text {
  color: #6a2f85;
}

.notready {
  min-height: 800px;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.nmb {
  margin-bottom: 0 !important;
}

.placeholder-item {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 2px;
  margin: 0 auto;
  padding: 5px;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.switcher-modal {
  width: 100%;
}

.main-modal {
  max-width: 600px;
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}

.switcher-head {
  padding: 20px;
  border-radius: 10px 10px 0 0;
}

.switcher-box {
  padding: 20px;
}

.profile-switchers {
  display: flex;
  flex-wrap: wrap;
}

.profile-switcher {
  margin: 10px;
  width: 100%;
}

.switcher {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
  background-color: #f5f5f5;
  transition: all 0.2s ease-in-out;
}

.switcher:hover {
  background-color: #d9d9d9;
}

.switcher__circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007aff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-right: 10px;
  flex-shrink: 0;
}

.switcher__info {
  display: flex;
  flex-direction: column;
}

.switcher__name {
  font-weight: bold;
}

.switcher__email {
  font-size: 14px;
  color: #7a7a7a;
}

@media only screen and (max-width: 600px) {
  .switcher__name {
    white-space: normal;
  }
}

.animated-background {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eeeeee;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, #eeeeee),
    color-stop(18%, #dddddd),
    color-stop(33%, #eeeeee)
  );
  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px;
  position: relative;
}

.outlined .background-masker {
  border: 1px solid #ddd;
}

.outlined:hover .background-masker {
  border: none;
}

.outlined:hover .background-masker:hover {
  border: 1px solid #ccc;
  z-index: 1;
}

.background-masker {
  background: #fff;
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

select.browser-default {
  border: 1px solid #ccc;
}

select.browser-default:disabled {
  background-color: #f6f6f6;
}

select.hourselect,
select.minuteselect,
select.ampmselect {
  display: unset;
  height: unset;
}

.icon-myacc {
  padding-top: 10px;
  height: 64px;
}

a.icon-myacc:hover div.profile-menu {
  visibility: visible;
  opacity: 1;
}

div.profile-menu {
  background-color: #fff;
  z-index: 1;
  position: absolute;
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  margin-top: 4px;
  margin-left: -15px;
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  width: 150px;
  right: 25px;
  top: 40px;
  color: #000;
  border-radius: 4px;
  padding: 10px 0;
}

div.profile-menu ul li {
  line-height: 1;
  clear: both;
  /* cursor: pointer; */
  /* padding: 12px 16px; */
  width: 100%;
  text-align: left;
}

div.profile-menu ul li a:hover {
  background-color: transparent;
  color: #ee7800;
}

div.profile-menu ul li a {
  padding: 8px 16px;
  color: #000;
  font-size: 0.9rem;
}

.logout-icon {
  font-size: 1rem !important;
  display: inline-block !important;
  height: unset !important;
  line-height: 1 !important;
  position: relative;
  top: 2px;
  font-weight: 600;
}

a.task:hover div.menu-dropdown {
  visibility: visible;
  opacity: 1;
}

div.menu-dropdown {
  background-color: #fff;
  text-align: start;
  position: absolute;
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  margin-top: 20px;
  visibility: hidden;
  opacity: 0;
  width: 140px;
  top: 40px;
  color: #000;
  border-radius: 4px;
  padding: 10px 0;
  margin-top: 8px;
  margin-left: -30px;
}

div.menu-dropdown ul li {
  line-height: 1;
  width: 100%;
}

div.menu-dropdown ul li a:hover {
  background-color: transparent;
  color: #ee7800;
}

div.menu-dropdown ul li a {
  padding: 8px 16px;
  color: #000;
  font-size: 15px;
}

.drawer-main-menu {
  display: flex;
  align-items: center;
  position: relative;
}

.arrow-right {
  font-size: 20px;
  position: absolute;
  right: 30px;
  top: 17px;
}

.expand_more {
  font-size: 20px;
  position: absolute;
  right: 30px;
  top: 17px;
  display: none;
}

.drawer-main-menu:hover .expand_more {
  display: block;
}

.drawer-main-menu:hover .arrow-right {
  display: none;
}

.drawer-dropdown {
  width: 100% !important;
  top: 87% !important;
  right: 0 !important;
}

.drawer-dropdown ul {
  margin-left: 10%;
  margin-top: -5%;
}

#toast {
  visibility: hidden;
  max-width: 50px;
  height: 50px;
  /*margin-left: -125px;*/
  margin: auto;
  background-color: rgb(0, 255, 42);
  color: #fff;
  text-align: center;
  border-radius: 2px;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 30px;
  font-size: 17px;
  white-space: nowrap;
}

#toast #desc {
  color: #fff;
  padding: 16px;
  overflow: hidden;
  white-space: nowrap;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 2s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 4s, fadeout 0.5s 4.5s;
}

.switcher-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.switcher-close {
  background-color: #ff7675 !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.multiselect__input {
  font-size: 14px !important;
  height: auto !important;
  border-bottom: none !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 3px 0 3px 0 !important;
  border-radius: 5px !important;
}

.el-select {
  font-size: 14px !important;
}

.el-select .input__wrapper,
.el-select .el-select__tags {
  padding: 3px 5px !important;
}

.el-select__input,
.el-input__inner {
  font-size: 14px !important;
  height: auto !important;
  border-bottom: none !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 2px 5px !important;
}

.el-input__inner {
  padding: 2px 0 !important;
}

.el-select__tags-text {
  max-width: 80px !important;
}

.multiselect__input,
.multiselect__single,
.multiselect__tags {
  background: inherit !important;
}

.vue-treeselect--has-value .vue-treeselect__multi-value {
  margin-bottom: 4px !important;
}

.vue-treeselect__multi-value-item-container {
  padding-top: 4px !important;
}

.multiselect,
.multiselect__single,
.multiselect__option {
  font-size: 13px !important;
}

.multiselect__single {
  margin-bottom: 5px !important;
  line-height: 24px !important;
}

.multiselect__option {
  min-height: 26px !important;
  line-height: 18px !important;
  padding: 3px 10px !important;
}

.multiselect__option::after {
  font-size: 12px !important;
  line-height: 26px !important;
}

.multiselect__tags {
  min-height: 26px !important;
  padding-top: 5px !important;
}

.multiselect__select {
  height: 34px !important;
  padding: 2px 8px !important;
}

.multiselect__placeholder {
  margin-bottom: 6px !important;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes expand {
  from {
    min-width: 50px;
  }

  to {
    min-width: 450px;
  }
}

@keyframes expand {
  from {
    min-width: 50px;
  }

  to {
    min-width: 450px;
  }
}

@-webkit-keyframes stay {
  from {
    min-width: 450px;
  }

  to {
    min-width: 450px;
  }
}

@keyframes stay {
  from {
    min-width: 450px;
  }

  to {
    min-width: 450px;
  }
}

@-webkit-keyframes shrink {
  from {
    min-width: 450px;
  }

  to {
    min-width: 50px;
  }
}

@keyframes shrink {
  from {
    min-width: 450px;
  }

  to {
    min-width: 00px;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 60px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 60px;
    opacity: 0;
  }
}
</style>

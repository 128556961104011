import jwt from "jsonwebtoken";
import Api from "../../Api";

const tokenFromStorage = localStorage && localStorage.SLToken ? localStorage.SLToken : "";

const accountState = {
  user: tokenFromStorage ? ({ affiliation: { LogoURL: "" }, ...jwt.decode(tokenFromStorage) }) : {},
};

const getters = {
  loggedIn: (state) => !!state.user.UserId,
  user: (state) => state.user,
};

const actions = {
  getProfile({ commit }) {
    Api.getProfile((response) => {
      commit("user", response);
    });
  },
};

const mutations = {
  user(state, user) {
    state.user = user;
  },
};

export default {
  state: accountState,
  getters,
  actions,
  mutations,
};

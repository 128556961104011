// import VueAppInsights from "vue-application-insights";
// import "materialize-css";
// import vClickOutside from "v-click-outside";
import { createApp, h } from "vue";
import VueCookies from "vue-cookies";
import VueGtag from "vue-gtag-next";
// import { FunctionalCalendar } from "vue-functional-calendar";
import NProgress from "vue-nprogress";
import VueDragscroll from "vue-dragscroll";
import vueVimeoPlayer from "vue-vimeo-player";
import VCalendar from "v-calendar";
import vClickOutside from "click-outside-vue3";
import VueMathjax from "vue-mathjax-next";
import Donut from "vue-css-donut-chart";
import VueConfetti from "vue-confetti";
import Toast from "vue-toastification";
import ElementPlus from "element-plus";
import mitt from "mitt";
import Api from "./Api";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Mobileapi from "./Mobileapi";
import Doubtapi from "./Doubtapi";
import "v-calendar/dist/style.css";
import "./assets/css/custom.css";
import "vue-css-donut-chart/dist/vcdonut.css";
import "vue-toastification/dist/index.css";
import "element-plus/dist/index.css";

// Vue.use(NProgress, {
//   router: true,
//   http: false,
// });
// Vue.use(VueWorker);
// Vue.use(VueGtag, {
//   config: {
//     id: process.env.VUE_APP_GTAG,
//   },
// }, router);

// Vue.use(VueAppInsights, {
//   // baseName: "SLWEBAPP",
//   id: process.env.VUE_APP_APP_INSIGHT,
//   router,
// });

// const nprogress = new NProgress();
// createApp({
//   nprogress,
//   render: () => h(App),
// }).use(router).use(store).mount("#app");
Api.init(router);
Mobileapi.init(router);
Doubtapi.init(router);

const nprogress = new NProgress();
// const myApp = createApp({
//   nprogress,
//   App,
// });
// myApp.use(router);
// myApp.use(store);
// myApp.use(vClickOutside);
// myApp.use(VueDragscroll);
// myApp.mount("#app");
const emitter = mitt();

const app = createApp({
  nprogress,
  render: () => h(App),
})
  .use(VueDragscroll)
  .use(vClickOutside)
  .use(VueConfetti)
  .use(VCalendar, {})
  .use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GTAG,
      },
    },
    router,
  )
  .use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 1,
    newestOnTop: true,
  })
  // .use(FunctionalCalendar)
  .use(VueMathjax)
  .use(router)
  .use(store)
  .use(Donut)
  .use(VueCookies)
  .use(ElementPlus)
  .use(vueVimeoPlayer);

app.config.globalProperties.emitter = emitter;
// TODO: Remove this line - Can be used to remove
// too many console warnings in treeselect
// app.config.warnHandler = () => null;
app.mount("#app");
